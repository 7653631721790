<template>
  <!-- login START -->
  <div class="login-holder">
    <!-- login:headline START -->
    <div class="description">
      <div class="description-inner">
        <p v-html="`${$t('login.future')}`"></p>
        <p>...</p>
      </div>
    </div>
    <!-- login:headline END -->

    <!-- login:options START -->
    <div class="options">
      <router-link
        class="btn-default"
        :to="`/login/sign-in`"
        v-html="$t('login.loginheadline')"
      ></router-link>
      <router-link
        class="btn-default grey"
        :to="`/register`"
        v-html="$t('login.registerheadline')"
      ></router-link>
      <router-link
        class="btn-default grey"
        :to="`/login/reset-password`"
        v-html="$t('login.resetpasswordheadline')"
      ></router-link>
    </div>
    <!-- login:options END -->

    <!-- form:login:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:login:loading END -->
  </div>
  <!-- login END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'login',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('login.loginheadline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.login.headline'),
      loadingExcerpt: this.$t('loading.login.excerpt'),
    };
  },
  created() {
    if (localStorage.getItem('lang') && localStorage.getItem('eToken') && localStorage.getItem('eToken') !== 'undefined') {
      this.loginByToken();
    }
  },
  methods: {
    loginByToken() {
      const formData = new FormData();
      this.loadingVisible = true;
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('lang'));
      /**
       * eToken
       */
      formData.append('token', localStorage.getItem('eToken'));

      this.$http.post(`${process.env.VUE_APP_BASE_URL}loginByToken`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
        },
      })
        .then((response) => {
          if (response.data) {
            const user = response.data;
            const auth = user.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', auth);
            localStorage.setItem('auth', auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', user);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('timeout', user.timeout);

            this.$router.push('/home');
          } else {
            // Close loading
            this.loadingVisible = false;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            // Close loading
            this.loadingVisible = false;
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });
          }
        });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
